import request from "@/utils/request";

export function OrderInfostatistics() {
  return request({
    url: "/sys/orderInfo/statistics",
    method: "get",
  });
}

export function querystatistics(data={}) {
  return request({
    url: "/sys/orderInfo/query/statistics",
    method: "post",
    data
  });
}


