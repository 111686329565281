<template>
  <div>
    <div class="box">
      <div class="overview">
        <img src="@/assets/img/line.png" alt="" />
        <span>概览</span>
      </div>
      <!-- 总预览统计 -->
      <div class="box-plan">
        <!-- satrt数据统计展示部分 -->
        <el-row :gutter="18">
          <el-col :span="6">
            <div class="text-module">
              <div class="imgs" style="background: #0078ff">
                <img src="@/assets/img/data.png" alt="" />
              </div>
              <div class="info">
                <p>本月已采集/人</p>
                <p class="num">{{ mouthCounts }}</p>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="text-module">
              <div class="imgs" style="background: #ff3a30">
                <img src="@/assets/img/data.png" alt="" />
              </div>

              <div class="info">
                <p>本月已收款/元</p>
                <p class="num">￥{{ mouthMoneys || "0.00" }}</p>
              </div>
            </div>
          </el-col>
   
          <el-col :span="4">
            <div class="text-module">
              <div class="imgs" style="background: #ff9502">
                <img src="@/assets/img/data.png" alt="" />
              </div>

              <div class="info">
                <p>采集人数（本年度）</p>
                <p class="num">{{ yearCounts }}</p>
              </div>
            </div>
          </el-col>
    
          <el-col :span="4">
            <div class="text-module">
              <div class="imgs" style="background: #2dc552">
                <img src="@/assets/img/data.png" alt="" />
              </div>

              <div class="info">
                <p>已收款/元（本年度）</p>
                <p class="num">￥{{ yearMoneys || "0.00" }}</p>
              </div>
            </div></el-col
          >
  
          <!-- <el-col :span="4">
            <div class="text-module" style="border:none">
              <div class="imgs" style="background: #5557d9">
                <img src="@/assets/img/data.png" alt="" />
              </div>

              <div class="info">
                <p>E钱包用户数</p>
                <p class="num">{{ epayCount || "0" }}</p>
              </div>
            </div></el-col
          > -->
        </el-row>
        <!--end数据统计展示部分 -->
      </div>
    </div>
    <div class="stats">
      <!-- 数据统计 -->

      <div class="overview">
        <img src="@/assets/img/line.png" alt="" />
        <span>数据统计</span>
      </div>
      <div class="condition-item">
        <label class="fn-14" style="margin-left: 20px">采集类型</label>
        <el-select
          v-model="searchStatus"
          size="small"
          clearable
          placeholder="采集类型"
          style="width: 120px"
        >
          <el-option label="校拍" :value="0"></el-option>
          <el-option label="散拍" :value="1"></el-option>
        </el-select>
        <label class="fn-14" style="margin-left: 20px">学生类型</label>
        <el-select
          v-model="searchShotType"
          size="small"
          clearable
          placeholder="学生类型"
          style="width: 120px"
        >
          <el-option label="新生" :value="0"></el-option>
          <el-option label="毕业生" :value="1"></el-option>
        </el-select>
        <label class="fn-14" style="margin-left: 20px">采集时间</label>
        <el-date-picker
          v-model="searchCreateDate"
          align="right"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始日期"
          autocomplete="off"
          size="small"
          style="width: 180px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding: 0 10px">-</label>
        <el-date-picker
          v-model="searchCreateDateEnd"
          align="right"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束日期"
          autocomplete="off"
          size="small"
          style="width: 180px"
        >
        </el-date-picker>
        <label class="fn-14"  style="padding: 0 10px">任务名称</label>
        <el-input
        style="width: 280px;"
          v-model="searchCreateTaskName"
          placeholder="任务名称"
          clearable
          autocomplete="off"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          >搜索
        </el-button>
      </div>

      <div class="stats-plan">
        <el-table
          :data="statisticsList"
          style="width: 100%"
          :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        >
          <el-table-column
            align="center"
            prop="typeName,typeStudent"
            label="类型"
            width="200"
          >
            <template slot-scope="scope">
              {{ scope.row.typeName }}{{ scope.row.typeStudent }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="peoples"
            label="采集人数"
            width="450"
          >
          </el-table-column>
          <el-table-column align="center" prop="money" label="金额合计">
            <template slot-scope="scope">
              ￥{{ scope.row.money | number_format }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 支付类型统计 -->
      <div class="overview">
        <img src="@/assets/img/line.png" alt="" />
        <span>支付类型统计</span>
      </div>

      <div class="stats-plan">
        <el-table
          :data="statisticsPayList"
          style="width: 100%"
          :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        >
          <el-table-column
            align="center"
            prop="typeName,typeStudent"
            label="类型"
            width="200"
          >
            <template slot-scope="scope">
              {{ scope.row.typeName }}{{ scope.row.typeStudent }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="peoples"
            label="采集人数"
            width="450"
          >
          </el-table-column>
          <el-table-column align="center" prop="money" label="金额合计">
            <template slot-scope="scope">
              ￥{{ scope.row.money | number_format }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 学历层次统计 -->
      <div class="overview">
        <img src="@/assets/img/line.png" alt="" />
        <span>学历层次统计</span>
      </div>

      <div class="stats-plan">
        <el-table
          :data="statisticSchoolList"
          style="width: 100%"
          :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        >
          <el-table-column
            align="center"
            prop="typeName,typeStudent"
            label="类型"
            width="200"
          >
            <template slot-scope="scope">
              {{ scope.row.educationTypeName }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="educationTypes"
            label="采集人数"
            width="450"
          >
          </el-table-column>
          <el-table-column align="center" prop="educationTypeMoney" label="金额合计">
            <template slot-scope="scope">
              ￥{{ scope.row.educationTypeMoney | number_format }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<style scoped>

/* 数据统计部分盒子分割线 */
.el-divider--vertical {
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
  position: relative;
  width: 1px;
  height: 3em;
}
/* satrt数据统计部分 */
.box {
  height: 130px;
  color: #000;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
/* 数据统计标题 */
.overview {
  padding: 15px 0px 10px 13px;
  display: flex;
  align-items: center;
  width: 140px;
  height: 30px;
  font-size: 14px;
}
.overview img {
  width: 20px;
}
/* 数据统计数据展示部分 */
.text-module {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ccc;
  /* width: 260px;
  height: 70px; */
}
.text-module div.imgs {
  width: 35px;
  height: 35px;
  border-radius: 2px;
}
.text-module div.imgs img {
  width: 100%;
}
.text-module div.info {
  margin-left: 20px;
  text-align: center;
  font-size: 14px;
  color: #999;
}
.text-module div.info p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}
.text-module div.info p.num {
  padding-top: 5px;
  font-size: 18px;
  color: #333;
}
/* end数据统计部分 */

/* start数据列表部分 */
.stats {
  margin-top: 15px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  padding-bottom: 15px;
  overflow: auto;
  max-height: 640px;
    /* height: 640px; */
}
.stats div.condition-item button {
  margin-left: 20px;
}
.stats div.stats-plan {
  padding: 0px 20px 0px 20px;
}
.stats div.stats-plan .el-table {
  border: #ededed 1px solid;
  border-bottom: none;
}
/* end数据列表部分 */
</style>

<script>
import { OrderInfostatistics, querystatistics } from "@/api/data";
import moment from "moment/moment";
import { number_format } from "@/filters/filters"; //金额格式化方式
export default {
  name: "Statistics",
  components: {
    number_format,
  },
  data() {
    return {
      //选择时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      mouthCounts: 0, //月人数
      mouthMoneys: 0.0, //月收款
      yearCounts: 0, //年人数
      yearMoneys: 0.0, //年收款
      epayCount: 0, //E钱包用户
      searchButton: false,
      searchStatus: null, //采集类型
      searchShotType: null, //学生类型
      searchCreateDate: "",
      searchCreateDateEnd: "",
      searchCreateTaskName:"",
      statisticsList: [],
      statisticsPayList: [],
      statisticSchoolList: [],
    };
  },

  created() {
    //默认显示一个月的数据
    var yestday = new Date();
    yestday.setTime(yestday.getTime() - 24 * 60 * 60 * 1000);
    this.searchCreateDate = moment(yestday)
      .startOf("month")
      .format("YYYY-MM-DD");
    this.searchCreateDateEnd = moment(yestday)
      .endOf("month")
      .format("YYYY-MM-DD");
  },
  mounted() {
    this.OrderInfostatistics(); //调用数据统计方法
    this.query();
  },

  methods: {
    // 数据统计
    OrderInfostatistics() {
      OrderInfostatistics().then((resp) => {
        const data = resp.data;
        // console.log(data);
        if (data.mouthCounts == null) {
          this.mouthCounts = 0;
        } else {
          this.mouthCounts = data.mouthCounts;
        }
        if (data.mouthMoneys == null) {
          this.mouthMoneys = 0.0;
        } else {
          this.mouthMoneys = data.mouthMoneys;
        }
        if (data.yearCounts == null) {
          this.yearCounts = 0;
        } else {
          this.yearCounts = data.yearCounts;
        }
        if (data.yearMoneys == null) {
          this.yearMoneys = 0.0;
        } else {
          this.yearMoneys = data.yearMoneys;
        }
        if (data.epayCount == null) {
          this.epayCount = 0;
        } else {
          this.epayCount = data.epayCount;
        }
      });
    },
    // 数据列表
    query() {
      const datas = {
        shotTypeCode: this.searchStatus != null ? this.searchStatus : "",
        shotSubject: this.searchShotType != null ? this.searchShotType : "",
        startTime: this.searchCreateDate || "",
        endTime: this.searchCreateDateEnd || "",
        taskName:this.searchCreateTaskName
      };
      querystatistics(datas).then((resp) => {
        // console.log(resp);
        if (resp.code == 0) {
          let datas = resp.data.detail;
          let taotal = resp.data.taotal;
          taotal.typeName = "合计";
          taotal.money = taotal.totalMoney;
          taotal.peoples = taotal.totalPeoples;
          taotal.typeStudent = taotal.typeStudent || "";
          datas.push(taotal);
          this.statisticsList = datas;
          //支付类型
          let dataspay = resp.data.payTpyeDetail;
          let taotalpay = resp.data.payTpyeTotal;
          taotalpay.typeName = "合计";
          taotalpay.money = taotalpay.totalMoney;
          taotalpay.peoples = taotalpay.totalPeoples;
          taotalpay.typeStudent = taotalpay.typeStudent || "";
          dataspay.push(taotalpay);
          this.statisticsPayList = dataspay;

          //学历类型
          let datasSchool = resp.data.educationTypes;
          let taotalSchool = resp.data.educationTypeTotal;
          taotalSchool.educationTypeName = "合计";
          taotalSchool.educationTypeMoney = taotalSchool.educationTypeTotalMoney;
          taotalSchool.educationTypes = taotalSchool.totalEducationTypes;
          taotalSchool.typeStudent = taotalSchool.typeStudent || "";
          datasSchool.push(taotalSchool);
          this.statisticSchoolList = datasSchool;
        }
      });
    },
    //搜索
    handleSearch() {
      this.query();
    },
    //重置
    resetSearch() {
      this.searchStatus = null;
      this.searchShotType = null;
      this.searchCreateDate = "";
      this.searchCreateDateEnd = "";
      this.searchCreateTaskName=""
      this.query();
    },
  },
};
</script>
